import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AdminService} from '../../shared/services/admin.service';
import {CourseService} from '../../shared/services/course.service';

@Component({
    selector: 'app-program',
    templateUrl: './program.component.html',
    styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit {
    showSearch = false;
    admins = [];
    id: any;
    data: any;
    updatePasswordForm: FormGroup;
    inputTypePassword = 'password';
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: 0,
        perpage: 15,
        pages: 0
    };


    dataToFilter = [];
    filteredData: any;
    matchedCourse: any | null;
    matchedItem: any;


    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private courses: CourseService,
                private adminService: AdminService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.updatePasswordForm = this.fb.group({
            'password': ['']
        });
        this.searchForm = this.fb.group({
            email: [''],
            contact: [''],
            course: ['']
        });


        // Assuming your data is fetched from a service

        // this.getCourse();
        // You can subscribe to form value changes and perform the filtering
        this.searchForm.valueChanges.subscribe(() => {
            this.search();
        });

        this.getCourse();
    }


    getCourse(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.dataToFilter = [];
        this.courses.getCourse(params).subscribe(res => {
                if (res && res['data'] && res['data'].length > 0) {
                    this.dataToFilter = res.data;
                    console.log('this.dataToFilter', this.dataToFilter);
                    this.pagination = {
                        page: res.current_page,
                        total: res.total,
                        perpage: res.per_page,
                        pages: res.last_page,
                    };
                }
            },
            (error) => {
                console.error('Error fetching course:', error);
                // Handle the error appropriately
            }
        );
    }


    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.getCourse({page: 1, total: null, perpage: 45, pages: null});
        }
    }


    /*get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.dataToFilter = [];
        this.courses.get(params).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.dataToFilter = data['data'];
                this.pagination = {
                    page: data.data.current_page,
                    total: data.data.total,
                    perpage: data.data.per_page,
                    pages: data.data.last_page,
                };
            } else {
                this.dataToFilter = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }*/


    onPageChange(page) {
        this.pagination.page = page;
        // this.filteredData = this.dataToFilter;

        const params = {
            'name': this.searchForm.value?.name || '',
            'email': this.searchForm.value?.email || '',
            'contact': this.searchForm.value?.contact || '',
            'course': this.searchForm.value?.course || ''
        };
        this.getCourse(params);

    }


    search() {
        const emailControl = this.searchForm.get('email');
        const contactControl = this.searchForm.get('contact');
        const courseControl = this.searchForm.get('course');

        if (!emailControl || !contactControl || !courseControl) {
            return;
        }

        const email = emailControl.value ? emailControl.value.toLowerCase() : '';
        const contact = contactControl.value ? contactControl.value.toString().toLowerCase() : '';
        const course = courseControl.value ? courseControl.value.toLowerCase() : '';

        // Your filtering logic here
        this.filteredData = this.dataToFilter.filter(item => {
            // Convert data to lowercase for case-insensitive matching
            const itemEmail = (item.email || '').toLowerCase();
            const itemContact = (item.contact || '').toString().toLowerCase();
            const itemCourse = (item.course || '').toLowerCase();

            // Check if each input value is either empty or matches the corresponding data field
            const emailMatch = email === '' || itemEmail.includes(email);
            const contactMatch = contact === '' || itemContact.includes(contact);
            const courseMatch = course === '' || itemCourse.includes(course);

            // Return true if all conditions are met
            return emailMatch && contactMatch && courseMatch;
        });

        // Display the data if there is only one match
        if (this.filteredData.length === 1) {
            this.showMatchedData(this.filteredData[0]);
        }
    }


    showMatchedData(matchedItem) {
        // Logic to display the data for the matched item
        this.matchedItem = matchedItem;
        // You can update your component properties or perform other actions here
    }


}
