<div class="content-wrapper">
    <div class="content-body">
        <section id="students">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'COUNSELLOR_ASSIGN'">
                            <i class="fas fa-flag-checkered cursor-pointer mr-2"></i>
                            <span>Assign Counsellor - {{selectedStudent?.name}}</span>
                        </h4>
                        <h4 *ngIf="modalType === 'FOLLOW_UP'">
                            <i class="fas fa-flag-checkered cursor-pointer mr-2"></i>
                            <span>Follow Up - {{selectedStudent?.name}}</span>
                        </h4>
                        <h4 *ngIf="modalType === 'VIEW_FOLLOW_UP_REMARKS'">
                            <i class="fas fa-flag-checkered cursor-pointer mr-2"></i>
                            <span>Follow Up Remarks - {{selectedStudent?.name}}</span>
                        </h4>
                        <i class="fas fa-times-circle pull-right text-danger cross-icon cursor-pointer"
                           (click)="this.modalService.dismissAll('')"></i>
                    </div>
                    <div class="modal-body">
                        <div class="row" *ngIf="modalType === 'COUNSELLOR_ASSIGN'">
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12"></div>
                            <form class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12 form mt-2 shadow-none"
                                  novalidate [formGroup]="assignCounsellorForm">
                                <div class="form-group">
                                    <label for="assigned_to">Assign Counsellor</label>
                                    <select class="form-control" id="assigned_to" formControlName="assigned_to">
                                        <option value="">Select Counsellor</option>
                                        <option [value]="counsellor?.user?.id"
                                                *ngFor="let counsellor of counsellors">{{counsellor?.user?.name}}</option>
                                    </select>
                                    <small *ngIf="submitted && assignCounsellorForm.get('assigned_to').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                            </form>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12"></div>
                        </div>
                        <div class="row" *ngIf="modalType === 'FOLLOW_UP'">
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12"></div>
                            <form class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12 form mt-2 shadow-none"
                                  novalidate [formGroup]="followUpForm">
                                <div class="form-group">
                                    <label for="follow_up">Follow Up</label>
                                    <select class="form-control" id="follow_up" formControlName="follow_up">
                                        <option value="">Select Follow Up</option>
                                        <option value="PENDING">Pending</option>
                                        <option value="CALL_DISCONNECTED">Call Disconnected</option>
                                        <option value="NOT_INTERESTED">Not Interested</option>
                                        <option value="INTERESTED">Interested</option>
                                    </select>
                                    <small *ngIf="submitted && followUpForm.get('follow_up').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-group">
                                    <label for="follow_up_remarks">Follow Up Remarks</label>
                                    <textarea class="form-control resize-none" rows="5" id="follow_up_remarks"
                                              formControlName="follow_up_remarks"></textarea>
                                </div>
                            </form>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12"></div>
                        </div>
                        <div class="row" *ngIf="modalType === 'VIEW_FOLLOW_UP_REMARKS'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h6 class="p-2">{{selectedStudent?.follow_up_remarks}}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success btn-raised" type="button"
                                *ngIf="modalType === 'COUNSELLOR_ASSIGN'"
                                (click)="assignCounsellor()">
                            Submit
                        </button>
                        <button class="btn btn-success btn-raised" type="button"
                                *ngIf="modalType === 'FOLLOW_UP'"
                                (click)="followUp()">
                            Submit
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Students</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No.</h6>
                                            </th>
                                            <th class="text-center"
                                                *ngIf="user && (['SUPER_ADMIN', 'ADMIN'].indexOf(user['user_type']) !== -1)">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Counsellor</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Follow Up</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Follow Up Remarks</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Contact</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Course</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Date</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Username</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Email</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Verification Status</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Status</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="students && students.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let student of students; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center max-width-250px"
                                                *ngIf="user && (['SUPER_ADMIN', 'ADMIN'].indexOf(user['user_type']) !== -1)">
                                                <span class="badge badge-primary cursor-pointer"
                                                      (click)="viewCounsellorsModal(content, student)">
                                                    {{student?.assigned?.name}}
                                                </span>
                                            </td>
                                            <td class="text-center">
                                                <span class="badge cursor-pointer"
                                                      (click)="changeFollowUp(content, student)"
                                                      [ngClass]="{
                                                        'badge-warning': student['follow_up'] === 'PENDING',
                                                        'badge-danger': student['follow_up'] === 'CALL_DISCONNECTED',
                                                        'badge-danger': student['follow_up'] === 'NOT_INTERESTED',
                                                        'badge-success': student['follow_up'] === 'INTERESTED'
                                                      }">
                                                    <span *ngIf="student['follow_up'] === 'PENDING'">Pending</span>
                                                    <span *ngIf="student['follow_up'] === 'CALL_DISCONNECTED'">Call Disconnected</span>
                                                    <span *ngIf="student['follow_up'] === 'NOT_INTERESTED'">Not Interested</span>
                                                    <span *ngIf="student['follow_up'] === 'INTERESTED'">Interested</span>
                                                </span>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1 cursor-pointer text-info"
                                                    *ngIf="student && student['follow_up_remarks']"
                                                    (click)="viewFollowUpRemarks(content, student)">View</h6>
                                                <h6 class="mt-1 cursor-pointer"
                                                    *ngIf="!student || !student['follow_up_remarks']">-</h6>
                                            </td>
                                            <td class="text-center min-width-250px max-width-250px">
                                                <h6 class="mt-1">{{student?.name}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{student?.contact}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{student?.course?.course}}</h6>
                                            </td>
                                            <td class="text-center min-width-250px">
                                                <h6 class="mt-1">{{student?.created_at | date}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{student?.username}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{student?.email}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1"
                                                    *ngIf="student && student['verification_status'] === 'VERIFIED'">
                                                    <span class="badge badge-success">Verified</span>
                                                </h6>
                                                <h6 class="mt-1"
                                                    *ngIf="student && student['verification_status'] === 'NOT_VERIFIED'">
                                                    <span class="badge badge-danger">Not Verified</span>
                                                </h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{student?.status}}</h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="13" class="text-center">
                                                    <h6>No data to show</h6>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="students && students.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let student of students; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-10">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{student?.name}}</h4>
                                </div>
                                <div class="col-2">
                                    <i class="fas fa-trash f_18 text-danger cursor-pointer"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Name - <h6 class="mt-1 cursor-pointer">{{student?.name}}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Username - <h6 class="mt-1 cursor-pointer">{{student?.username}}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Eamil - <h6 class="mt-1 cursor-pointer">{{student?.email}}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Contact - <h6 class="mt-1 cursor-pointer">{{student?.contact}}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Course - <h6 class="mt-1 cursor-pointer">{{student?.course?.course}}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Verification Status -
                                    <h6 class="mt-1" *ngIf="student && student['verification_status'] === 'VERIFIED'">
                                        <i class="fas fa-check-circle text-success f_18"></i>
                                    </h6>
                                    <h6 class="mt-1"
                                        *ngIf="student && student['verification_status'] === 'NOT_VERIFIED'">
                                        <i class="fas fa-times-circle text-danger f_18"></i>
                                    </h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Status - <h6 class="mt-1">{{student?.status}}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Assigned To - <h6 class="mt-1">{{student?.assigned?.name}}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Follow Up - <h6 class="mt-1">{{student?.follow_up}}</h6>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Remark - <h6 class="mt-1">{{student?.follow_up_remarks}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
</div>
