import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

    static get API_URL(): string {
        return environment.appUrl + 'api/';
    }

    static get APP_URL(): string {
        return environment.appUrl + 'admin/';
    }

    static get LOGIN(): string {
        return AppUrl.APP_URL + 'login';
    }

    static get SELF(): string {
        return AppUrl.APP_URL + 'self';
    }

    static UPDATE_SELF(): string {
        return AppUrl.API_URL + 'update-self';
    }

    static LOGOUT(): string {
        return AppUrl.API_URL + 'logout';
    }

    static get VERIFY_USER(): string {
        return AppUrl.APP_URL + 'verify-user';
    }

    static COUNSELLORS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'counsellor/' + id;
        } else {
            return AppUrl.APP_URL + 'counsellor';
        }
    }

    static COUNSELLORS_PROGRESS_REPORT_COUNT(id): string {
        return AppUrl.APP_URL + 'counsellors-report/' + id;
    }

    static COUNSELLOR_PASSWORD(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'counsellor-password/' + id;
        }
    }

    static COUNSELLOR_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'counsellor-status/' + id;
        }
    }

    static COUNSELLOR_UNIVERSITY(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'counsellor-university/' + id;
        }
    }

    static COUNSELLORS_COUNT(): string {
        return AppUrl.APP_URL + 'counsellors-count';
    }


    static ADMINS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admin/' + id;
        } else {
            return AppUrl.APP_URL + 'admin';
        }
    }

    static ADMIN_PASSWORD(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admin-password/' + id;
        }
    }

    static ADMIN_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admin-status/' + id;
        }
    }

    static ADMINS_COUNT(): string {
        return AppUrl.APP_URL + 'admins-count';
    }

    static ADMISSION(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admissions-info/' + id;
        } else {
            return AppUrl.APP_URL + 'admissions-info';
        }
    }

    static ADMISSION_PASSWORD(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admissions-info-password/' + id;
        }
    }

    static ADMISSION_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admissions-info-status/' + id;
        }
    }

    static ADMISSION_COUNT(): string {
        return AppUrl.APP_URL + 'admissions-info-count';
    }


    static UNIVERSITIES(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university/' + id;
        } else {
            return AppUrl.APP_URL + 'university';
        }
    }

    static UNIVERSITY_BY_PCB_MAPPING(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-by-pcb-mapping/' + id;
        }
    }

    static UNIVERSITIES_PASSWORD(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-password/' + id;
        } else {
            return AppUrl.APP_URL + 'university-password';
        }
    }

    static UNIVERSITIES_PROFILE_IMAGE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-profile-image/' + id;
        } else {
            return AppUrl.APP_URL + 'university-profile-image';
        }
    }

    static HEAD_COUNSELLORS_PROFILE_IMAGE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'counsellor-profile-image/' + id;
        } else {
            return AppUrl.APP_URL + 'counsellor-profile-image';
        }
    }

    static COUNSELLORS_PROFILE_IMAGE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'counsellor-profile-image/' + id;
        } else {
            return AppUrl.APP_URL + 'counsellor-profile-image';
        }
    }

    static ADMINS_PROFILE_IMAGE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admin-profile-image/' + id;
        } else {
            return AppUrl.APP_URL + 'admin-profile-image';
        }
    }

    static UNIVERSITIES_COUNT(): string {
        return AppUrl.APP_URL + 'university-count';
    }

    static UNIVERSITIES_STATUS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'university-setting-status/' + id;
        }
    }

    static UNIVERSITY_SETTINGS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'university-setting/' + id;
        } else {
            return AppUrl.API_URL + 'university-setting';
        }
    }

    static UNIVERSITY_SETTINGS_STATUS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'university-setting-status/' + id;
        }
    }

    static UNIVERSITIES_LANDING(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-by-landing/' + id;
        } else {
            return AppUrl.APP_URL + 'university-by-landing';
        }
    }

    static PROGRAMS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'program/' + id;
        } else {
            return AppUrl.API_URL + 'program';
        }
    }

    static PROGRAMS_BY_ADMIN(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'program/' + id;
        } else {
            return AppUrl.APP_URL + 'program';
        }
    }

    static PROGRAMS_UPDATE_BY_ADMIN(universityId, id): string {
        if (universityId && id) {
            return AppUrl.APP_URL + 'program/' + universityId + '/' + id;
        }
    }

    static PROGRAMS_PASSWORD(id?): string {
        if (id) {
            return AppUrl.API_URL + 'program/' + id;
        } else {
            return AppUrl.API_URL + 'program';
        }
    }

    static PROGRAMS_STATUS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'program-status/' + id;
        }
    }

    static PROGRAMS_COUNT(id?): string {
        if (id) {
            return AppUrl.API_URL + 'program-count/' + id;
        }
    }

    static ALL_PROGRAMS_COUNT(): string {
        return AppUrl.API_URL + 'program-count';
    }
    static ALL_CONTACT(): string {
        return AppUrl.API_URL + 'contact-us';
    }

    static COURSES(id?): string {
        if (id) {/*import {BranchService} from '../../../../shared/services/branch.service';*/
            return AppUrl.API_URL + 'course/' + id;
        } else {
            return AppUrl.API_URL + 'course';
        }
    }

    static COURSES_UPDATE_BY_ADMIN(universityId, id): string {
        if (universityId && id) {
            return AppUrl.APP_URL + 'course/' + universityId + '/' + id;
        }
    }

    static UNIVERSITY_COURSES(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'course/' + id;
        } else {
            return AppUrl.APP_URL + 'course';
        }
    }

    static COURSES_COUNT(id?): string {
        if (id) {
            return AppUrl.API_URL + 'course-count/' + id;
        }
    }

    static ALL_COURSES_COUNT(): string {
        return AppUrl.API_URL + 'course-count';
    }

    static COURSES_STATUS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'course-status/' + id;
        }
    }

    static BRANCHES(id?): string {
        if (id) {
            return AppUrl.API_URL + 'branch/' + id;
        } else {
            return AppUrl.API_URL + 'branch';
        }
    }

    static BRANCHES_COUNT(id?): string {
        if (id) {
            return AppUrl.API_URL + 'branch-count/' + id;
        }
    }

    static ALL_BRANCHES_COUNT(): string {
        return AppUrl.API_URL + 'branch-count';
    }

    static BRANCHES_STATUS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'branch-status/' + id;
        }
    }

    static UNIVERSITY_BRANCHES(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'branch/' + id;
        } else {
            return AppUrl.APP_URL + 'branch';
        }
    }

    static BRANCHES_UPDATE_BY_ADMIN(universityId, id): string {
        if (universityId && id) {
            return AppUrl.APP_URL + 'branch/' + universityId + '/' + id;
        }
    }

    static PCB_MAPPINGS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'program-course-branch-mapping/' + id;
        } else {
            return AppUrl.API_URL + 'program-course-branch-mapping';
        }
    }

    static PCB_MAPPINGS_STATUS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'program-course-branch-mapping-status/' + id;
        }
    }


    static PCB_MAPPINGS_BY_ADMIN(universityId?): string {
        if (universityId) {
            return AppUrl.APP_URL + 'program-course-branch-mapping/' + universityId;
        }
    }

    static PCB_MAPPINGS_PASSWORD(id?): string {
        if (id) {
            return AppUrl.API_URL + 'program-course-branch-mapping/' + id;
        } else {
            return AppUrl.API_URL + 'program-course-branch-mapping';
        }
    }


    static PCB_MAPPINGS_COUNT(id?): string {
        if (id) {
            return AppUrl.API_URL + 'program-course-branch-mapping-count/' + id;
        }
    }

    static PCB_MAPPINGS_BY_LANDING(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'program-course-branch-mapping-by-landing-page/' + id;
        } else {
            return AppUrl.APP_URL + 'program-course-branch-mapping-by-landing-page';
        }
    }

    static UNIVERSITY_PCB_MAPPINGS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'program-course-branch-mapping/' + id;
        } else {
            return AppUrl.APP_URL + 'program-course-branch-mapping';
        }
    }

    static UNIVERSITY_PCB_MAPPINGS_COURSES(id): string {
        if (id) {
            return AppUrl.APP_URL + 'program-course-branch-mapping-courses/' + id;
        }
    }

    static UNIVERSITY_PCB_MAPPINGS_BRANCHES(id): string {
        if (id) {
            return AppUrl.APP_URL + 'program-course-branch-mapping-branches/' + id;
        }
    }

    static PCB_MAPPINGS_UPDATE_BY_ADMIN(universityId, id): string {
        if (universityId && id) {
            return AppUrl.APP_URL + 'program-course-branch-mapping/' + universityId + '/' + id;
        }
    }

    static PCB_MAPPINGS_FEE_STRUCTURE_BY_ADMIN(universityId): string {
        if (universityId) {
            return AppUrl.APP_URL + 'program-course-branch-mapping-fee-structure-update/' + universityId;
        }
    }

    static PCB_MAPPINGS_STATUS_UPDATE_BY_ADMIN(universityId, id): string {
        if (universityId && id) {
            return AppUrl.APP_URL + 'program-course-branch-mapping-status/' + universityId + '/' + id;
        }
    }

    static COURSES_CURRICULUM(id?): string {
        if (id) {
            return AppUrl.API_URL + 'course-curriculum/' + id;
        } else {
            return AppUrl.API_URL + 'course-curriculum';
        }
    }


    static COURSES_CURRICULUM_COUNT(id?): string {
        if (id) {
            return AppUrl.API_URL + 'course-curriculum-count/' + id;
        }
    }

    static COURSES_CURRICULUM_STATUS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'course-curriculum-status/' + id;
        }
    }


    static STAGES(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'stage/' + id;
        } else {
            return AppUrl.APP_URL + 'stage';
        }
    }

    static STAGES_COUNT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'stage-count/' + id;
        }
    }

    static STAGES_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'stage-status/' + id;
        }
    }

    static REASONS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'reason/' + id;
        } else {
            return AppUrl.APP_URL + 'reason';
        }
    }

    static REASONS_COUNT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'reason-count/' + id;
        }
    }

    static REASONS_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'reason-status/' + id;
        }
    }

    static SRMAPPINGS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'stage-reason-mapping/' + id;
        } else {
            return AppUrl.APP_URL + 'stage-reason-mapping';
        }
    }

    static SRMAPPINGS_BY_STAGES(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'stage-reason-mapping-by-stage/' + id;
        }
    }

    static SRMAPPINGS_COUNT(id?): string {
        if (id) {
            return AppUrl.APP_URL + '-count/' + id;
        }
    }

    static SRMAPPINGS_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + '-status/' + id;
        }
    }

    static UNIVERSITY_OVERVIEWS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-overview/' + id;
        } else {
            return AppUrl.APP_URL + 'university-overview';
        }
    }

    static UNIVERSITY_OVERVIEWS_COUNT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-overview-count/' + id;
        }
    }

    static ALL_UNIVERSITY_OVERVIEWS_COUNT(): string {
        return AppUrl.APP_URL + 'university-overview-count';
    }

    static UNIVERSITY_OVERVIEWS_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-overview-status/' + id;
        }
    }

    static GET_UNIVERSITY_OVERVIEWS(id?): string {
        /*if (id) {
            return AppUrl.APP_URL + 'university-overview/' + id;
        } else {
            return AppUrl.APP_URL + 'university-overview';
        }*/
        return AppUrl.APP_URL + 'university-overview';
    }

    static UNIVERSITY_OVERVIEWS_UPDATE_BY_ADMIN(universityId, id): string {
        if (universityId && id) {
            return AppUrl.APP_URL + 'university-overview/' + universityId + '/' + id;
        }
    }

    static STUDENTS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'students/' + id;
        } else {
            return AppUrl.APP_URL + 'students';
        }
    }

    static PERSONALIZE_GUIDANCE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'personalize-guidance/' + id;
        } else {
            return AppUrl.APP_URL + 'personalize-guidance';
        }
    }

    static ASSIGN_COUNSELLOR_PERSONALIZE_GUIDANCE(id): string {
        return AppUrl.APP_URL + 'assign-counsellor-personalize-guidance/' + id;
    }

    static FOLLOW_UP_PERSONALIZE_GUIDANCE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'follow-up-personalize-guidance/' + id;
        } else {
            return AppUrl.APP_URL + 'follow-up-personalize-guidance';
        }
    }

    static ASK_EXPERT_PROFESSIONAL(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'ask-expert-professional/' + id;
        } else {
            return AppUrl.APP_URL + 'ask-expert-professional';
        }
    }

    static ASSIGN_COUNSELLOR_ASK_EXPERT_PROFESSIONAL(id): string {
        return AppUrl.APP_URL + 'assign-counsellor-ask-expert-professional/' + id;
    }

    static FOLLOW_UP_ASK_EXPERT_PROFESSIONAL(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'follow-up-ask-expert-professional/' + id;
        } else {
            return AppUrl.APP_URL + 'follow-up-ask-expert-professional';
        }
    }

    static CONSULT_YOUR_GUIDE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'consult-your-guide/' + id;
        } else {
            return AppUrl.APP_URL + 'consult-your-guide';
        }
    }

    static ASSIGN_COUNSELLOR_CONSULT_YOUR_GUIDE(id): string {
        return AppUrl.APP_URL + 'assign-counsellor-consult-your-guide/' + id;
    }

    static FOLLOW_UP_CONSULT_YOUR_GUIDE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'follow-up-consult-your-guide/' + id;
        } else {
            return AppUrl.APP_URL + 'follow-up-consult-your-guide';
        }
    }

    static STUDENTS_BY_STAGE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'student-by-stage/' + id;
        }
    }

    static UNASSIGNED_STUDENTS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'un-assigned-student/' + id;
        } else {
            return AppUrl.APP_URL + 'un-assigned-student';
        }
    }

    static STUDENTS_DETAILS_BY_COUNSELLOR(id?): string {
        if (id) {
            return AppUrl.API_URL + 'student/' + id;
        } else {
            return AppUrl.API_URL + 'student';
        }
    }

    static STUDENTS_RECEIPT_URL(id): string {
        return AppUrl.API_URL + 'student-receipt-url/' + id;
    }

    static STUDENTS_REGISTER(): string {
        return AppUrl.API_URL + 'student-register';
    }

    static STUDENTS_BULK_REGISTER(): string {
        return AppUrl.API_URL + 'student-bulk-register';
    }

    static STUDENT_PASSWORD(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'student/' + id;
        } else {
            return AppUrl.APP_URL + 'student';
        }
    }

    static STUDENTS_COUNT(): string {
        return AppUrl.APP_URL + 'student-all-count';
    }

    static STUDENTS_ADMISSION_COUNT(): string {
        return AppUrl.APP_URL + 'student-admission-count';
    }

    static STUDENTS_IN_PROGRESS_COUNT(): string {
        return AppUrl.APP_URL + 'student-in-progress-count';
    }

    static STUDENTS_NOT_CONNECTED_COUNT(): string {
        return AppUrl.APP_URL + 'student-not-connected-count';
    }

    static STUDENT_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'student-status/' + id;
        }
    }

    static STUDENT_STAGE_STATUS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'student-stage-status/' + id;
        } else {
            return AppUrl.API_URL + 'student-stage-status';
        }
    }

    static STUDENT_STAGE_STATUS_COUNT(): string {
        return AppUrl.API_URL + 'student-stage-status-count';
    }

    static STUDENT_ADDRESS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'student-address/' + id;
        } else {
            return AppUrl.APP_URL + 'student-address';
        }
    }

    static STUDENT_COURSE_DETAILS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'student-course-details/' + id;
        } else {
            return AppUrl.APP_URL + 'student-course-details';
        }
    }

    static STUDENT_MORE_QUALIFICATION_DETAILS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'student-more-qualification/' + id;
        } else {
            return AppUrl.APP_URL + 'student-more-qualification';
        }
    }

    static STUDENT_PERSONAL_INFO(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'student-personal-info/' + id;
        } else {
            return AppUrl.APP_URL + 'student-personal-info';
        }
    }

    static STUDENT_QUALIFICATION_DETAILS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'student-qualification/' + id;
        } else {
            return AppUrl.APP_URL + 'student-qualification';
        }
    }

    static STUDENTS_BULK_UPLOAD(): string {
        return AppUrl.API_URL + 'student-bulk-upload';
    }

    static ASSIGN_STUDENTS(): string {
        return AppUrl.APP_URL + 'assign-student';
    }

    static ASSIGN_COUNSELLOR(id): string {
        return AppUrl.APP_URL + 'assign-counsellor/' + id;
    }

    static FOLLOW_UP(id): string {
        return AppUrl.APP_URL + 'follow-up/' + id;
    }

    static TEXT_MESSAGES(id?): string {
        if (id) {
            return AppUrl.API_URL + 'text-messages/' + id;
        } else {
            return AppUrl.API_URL + 'text-messages';
        }
    }

    static TEXT_MESSAGE_PASSWORD(id?): string {
        if (id) {
            return AppUrl.API_URL + 'text-messages-password/' + id;
        }
    }

    static TEXT_MESSAGE_STATUS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'text-messages-status/' + id;
        }
    }

    static TEXT_MESSAGES_COUNT(): string {
        return AppUrl.API_URL + 'text-messages-count';
    }

    static WHATSAPPS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'whatsapp/' + id;
        } else {
            return AppUrl.API_URL + 'whatsapp';
        }
    }

    static WHATSAPP_PASSWORD(id?): string {
        if (id) {
            return AppUrl.API_URL + 'whatsapp-password/' + id;
        }
    }

    static WHATSAPP_STATUS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'whatsapp-status/' + id;
        }
    }

    static WHATSAPPS_COUNT(): string {
        return AppUrl.API_URL + 'text-messages-count';
    }

    static FILE_UPLOAD(): string {
        return AppUrl.API_URL + 'file-upload';
    }

    static FILE_UPLOAD_ADMIN(): string {
        return AppUrl.APP_URL + 'file-upload-admin';
    }

    static LANDING_PAGE_SLIDER(id?): string {
        if (id) {
            return AppUrl.API_URL + 'landing-page-slider/' + id;
        } else {
            return AppUrl.API_URL + 'landing-page-slider';
        }
    }

    static LANDING_PAGE_ABOUT_US(id?): string {
        if (id) {
            return AppUrl.API_URL + 'landing-page-about-us/' + id;
        } else {
            return AppUrl.API_URL + 'landing-page-about-us';
        }
    }

    static LANDING_PAGE_CONTACT_US(id?): string {
        if (id) {
            return AppUrl.API_URL + 'landing-page-contact-us/' + id;
        } else {
            return AppUrl.API_URL + 'landing-page-contact-us';
        }
    }

    static LANDING_PAGE_COURSE(id?): string {
        if (id) {
            return AppUrl.API_URL + 'landing-page-course/' + id;
        } else {
            return AppUrl.API_URL + 'landing-page-course';
        }
    }

    static LANDING_PAGE_WHY_US(id?): string {
        if (id) {
            return AppUrl.API_URL + 'landing-page-why-us/' + id;
        } else {
            return AppUrl.API_URL + 'landing-page-why-us';
        }
    }


    static STUDENT_FOLLOW_UP(id?): string {
        if (id) {
            return AppUrl.API_URL + 'student-follow-up/' + id;
        } else {
            return AppUrl.API_URL + 'student-follow-up';
        }
    }


    static COURIER_EXECUTIVES(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'courier-executive/' + id;
        } else {
            return AppUrl.APP_URL + 'courier-executive';
        }
    }

    static COURIER_EXECUTIVE_PASSWORD(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'courier-executive-password/' + id;
        }
    }

    static COURIER_EXECUTIVE_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'courier-executive-status/' + id;
        }
    }

    static COURIER_EXECUTIVES_COUNT(): string {
        return AppUrl.APP_URL + 'courier-head-counsellors-count';
    }

    static UPDATE_USER_PASSWORD(): string {
        return AppUrl.API_URL + 'update-user-password';
    }

    static CENTRES(id?): string {
        if (id) {
            return AppUrl.API_URL + 'centre/' + id;
        } else {
            return AppUrl.API_URL + 'centre';
        }
    }

    static DOWNLOAD_CENTRES(): string {
        return AppUrl.APP_URL + 'centres/download';
    }

    static DOWNLOAD_PAYMENTS(): string {
        return AppUrl.APP_URL + 'centres/download-payment';
    }

    static DOWNLOAD_ISSUES(): string {
        return AppUrl.APP_URL + 'issues/download';
    }

    static DOWNLOAD_COURIERS(): string {
        return AppUrl.APP_URL + 'couriers/download';
    }

    static COURIERS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'courier/' + id + '/add';
        }
    }

    static GET_COURIERS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'courier/' + id;
        }
    }

    static GET_ALL_COURIERS(): string {
        return AppUrl.API_URL + 'couriers';
    }

    static DETAILS_COURIER(id?): string {
        if (id) {
            return AppUrl.API_URL + 'courier/' + id + '/detail';
        }
    }

    static UPDATE_COURIERS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'courier/' + id + '/update';
        }
    }

    static DELETE_COURIER(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'courier/' + id;
        }
    }

    static CENTRE_PAYMENT(id?): string {
        if (id) {
            return AppUrl.API_URL + 'centre-payment/' + id;
        }
    }

    static CENTRES_COUNT(): string {
        return AppUrl.API_URL + 'centres-count';
    }

    static COURIERS_COUNT(): string {
        return AppUrl.API_URL + 'couriers-count';
    }

    static CENTRES_ADDED_BY_USER(id?): string {
        if (id) {
            return AppUrl.API_URL + 'centres-added-by-user-count/' + id;
        } else {
            return AppUrl.API_URL + 'centres-added-by-user-count';
        }
    }

    static STATES(): string {
        return AppUrl.APP_URL + 'states';
    }

    static STATUSES(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'status/' + id;
        } else {
            return AppUrl.APP_URL + 'status';
        }
    }

    static STATUS_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'status-status/' + id;
        }
    }

    static LABELS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'label/' + id;
        } else {
            return AppUrl.APP_URL + 'label';
        }
    }

    static LABEL_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'label-status/' + id;
        }
    }

    static ISSUES(centreId): string {
        return AppUrl.API_URL + `${centreId}/issue`;
    }

    static ISSUES_COUNT(): string {
        return AppUrl.API_URL + 'issues-count';
    }

    static DELETE_ISSUE(id): string {
        return AppUrl.APP_URL + `issue/` + id;
    }

    static ISSUES_PENDING_COUNT(): string {
        return AppUrl.API_URL + 'pending-issues-count';
    }

    static ISSUES_ADDED_BY_USER_COUNT(id?): string {
        if (id) {
            return AppUrl.API_URL + 'issues-added-by-user-count/' + id;
        } else {
            return AppUrl.API_URL + 'issues-added-by-user-count';
        }
    }

    static ISSUES_IN_PROGRESS_BY_USER_COUNT(id?): string {
        if (id) {
            return AppUrl.API_URL + 'issues-in-progress-by-user-count/' + id;
        } else {
            return AppUrl.API_URL + 'issues-in-progress-by-user-count';
        }
    }

    static ISSUES_IN_PROGRESS_COUNT(): string {
        return AppUrl.API_URL + 'in-progress-issues-count';
    }

    static ISSUES_RESOLVED_COUNT(): string {
        return AppUrl.API_URL + 'resolved-issues-count';
    }

    static ISSUES_RESOLVED_BY_USER_COUNT(id?): string {
        if (id) {
            return AppUrl.API_URL + 'issues-resolved-by-user-count/' + id;
        } else {
            return AppUrl.API_URL + 'issues-resolved-by-user-count';
        }
    }

    static ISSUE_UPDATION(issueId, centreId): string {
        return AppUrl.API_URL + `${centreId}/issue/${issueId}/updation`;
    }

    static SELF_ACTIVITY_LOGS(): string {
        return AppUrl.API_URL + 'self-activities';
    }

    static ALL_ACTIVITY_LOGS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'all-activities/' + id;
        } else {
            return AppUrl.APP_URL + 'all-activities';
        }
    }

    static SUBJECTS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'subject/' + id;
        } else {
            return AppUrl.APP_URL + 'subject';
        }
    }

    static SUBJECT_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'subject-status/' + id;
        }
    }

    static CONVERSATIONS(id?): string {
        if (id) {
            return AppUrl.API_URL + 'conversation/' + id;
        } else {
            return AppUrl.API_URL + 'conversation';
        }
    }

    static EXECUTIVE_CONVERSATIONS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'conversation/' + id;
        }
    }

    static NOTIFICATIONS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'notification/' + id;
        } else {
            return AppUrl.APP_URL + 'notification';
        }
    }

    static NOTIFICATIONS_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'notification-status/' + id;
        }
    }



    static NEWS_CATEGORY(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'news-category/' + id;
        } else {
            return AppUrl.APP_URL + 'news-category';
        }
    }

    static NEWS_CATEGORY_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'news-category-status/' + id;
        }
    }


    static NEWS_SUBCATEGORY(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'news-subcategory/' + id;
        } else {
            return AppUrl.APP_URL + 'news-subcategory';
        }
    }

    static NEWS_SUBCATEGORY_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'news-subcategory-status/' + id;
        }
    }



    static INSIGHTS_CATEGORY(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'insights-category/' + id;
        } else {
            return AppUrl.APP_URL + 'insights-category';
        }
    }

    static INSIGHTS_CATEGORY_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'insights-category-status/' + id;
        }
    }


    static INSIGHTS_SUBCATEGORY(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'insights-subcategory/' + id;
        } else {
            return AppUrl.APP_URL + 'insights-subcategory';
        }
    }

    static INSIGHTS_SUBCATEGORY_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'insights-subcategory-status/' + id;
        }
    }



 static ADMISSION_NOTIFICATIONS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admission-notification/' + id;
        } else {
            return AppUrl.APP_URL + 'admission-notification';
        }
    }

    static ADMISSION_NOTIFICATIONS_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admission-notification-status/' + id;
        }
    }

    static UNIVERSITY_COURSE_CURRICULUM(id): string {
        if (id) {
            return AppUrl.APP_URL + 'university-course-curriculum/' + id;
        } else {
            return AppUrl.APP_URL + 'university-course-curriculum';
        }
    }

    static UNIVERSITY_COURSE_CURRICULUM_BY_PCB_ID(id): string {
        return AppUrl.APP_URL + 'university-course-curriculum-by-pcb/' + id;
    }

    static UNIVERSITY_POPULAR_COURSE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'popular-courses/' + id;
        } else {
            return AppUrl.APP_URL + 'popular-courses';
        }
    }

    static POPULAR_COURSE_URL(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'popular-courses-url/' + id;
        }
    }

    static INSIGHTS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'insights/' + id;
        } else {
            return AppUrl.APP_URL + 'insights';
        }
    }
    static NEWS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'news/' + id;
        } else {
            return AppUrl.APP_URL + 'news';
        }
    }

    static MAIN_BANNER_SLIDER(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'main-banner-slider/' + id;
        } else {
            return AppUrl.APP_URL + 'main-banner-slider';
        }
    }

    static MAIN_BANNER_SLIDER_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'main-banner-slider-status/' + id;
        }
    }

    static UNIVERSITY_INFRASTRUCTURE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-infrastructure/' + id;
        } else {
            return AppUrl.APP_URL + 'university-infrastructure';
        }
    }

    static UNIVERSITY_OVERVIEW(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-overview/' + id;
        } else {
            return AppUrl.APP_URL + 'university-overview';
        }
    }

    static UNIVERSITY_INFRASTRUCTURE_COUNT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-infrastructure-count/' + id;
        }
    }

    static ALL_UNIVERSITY_INFRASTRUCTURE_COUNT(): string {
        return AppUrl.APP_URL + 'university-infrastructure-count';
    }

    static UNIVERSITY_INFRASTRUCTURE_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-infrastructure-status/' + id;
        }
    }

    static GET_UNIVERSITY_INFRASTRUCTURE(id?): string {
        return AppUrl.APP_URL + 'university-infrastructure/' + id;
    }

    static UNIVERSITY_INFRASTRUCTURE_UPDATE_BY_ADMIN(universityId, id): string {
        if (universityId && id) {
            return AppUrl.APP_URL + 'university-infrastructure/' + universityId + '/' + id;
        }
    }

    static UNIVERSITY_INFRASTRUCTURE_DELETE_BY_ADMIN(universityId, id): string {
        if (universityId && id) {
            return AppUrl.APP_URL + 'university-infrastructure/' + universityId + '/' + id;
        }
    }

    static UNIVERSITY_CONTACT_INFO(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-contact-info/' + id;
        } else {
            return AppUrl.APP_URL + 'university-contact-info';
        }
    }

    static GET_UNIVERSITY_CONTACT_INFO(id?): string {
        return AppUrl.APP_URL + 'university-contact-info/' + id;
    }

    static UNIVERSITY_CONTACT_INFO_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-contact-info-status/' + id;
        }
    }

    static UNIVERSITY_ADMISSION(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-admission/' + id;
        } else {
            return AppUrl.APP_URL + 'university-admission';
        }
    }

    static GET_UNIVERSITY_ADMISSION(id?): string {
        return AppUrl.APP_URL + 'university-admission/' + id;
    }

    static UNIVERSITY_ADMISSION_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'university-admission-status/' + id;
        }
    }

    static POPULAR_COURSE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'popular-courses/' + id;
        } else {
            return AppUrl.APP_URL + 'popular-courses';
        }
    }

    static POPULAR_COURSE_INFO(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'popular-courses-info/' + id;
        }
    }

    /* static INSIGHTS(id?): string {
         if (id) {
             return AppUrl.APP_URL + 'insights/' + id;
         } else {
             return AppUrl.APP_URL + 'insights';
         }
     }*/

    static INSIGHTS_CONTENT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'insights-content/' + id;
        }
    }

    static GET_INSIGHTS_CONTENT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'insights-content/' + id;
        }
    }

    static NEWS_CONTENT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'news-content/' + id;
        }
    }

    static GET_NEWS_CONTENT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'news-content/' + id;
        }
    }

    static POPULAR_COURSE_INFO_DELETE_BY_ADMIN(universityId, id): string {
        if (universityId && id) {
            return AppUrl.APP_URL + 'popular-course-info/' + universityId + '/' + id;
        }
    }

    static GET_POPULAR_COURSE_INFO(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'popular-course-info/' + id;
        }
    }

    static TOP_STUDY_LOCATION(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'top-study-location/' + id;
        } else {
            return AppUrl.APP_URL + 'top-study-location';
        }
    }

    static TOP_STUDY_LOCATION_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'top-study-location-status/' + id;
        }
    }

    static CITY(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'city/' + id;
        } else {
            return AppUrl.APP_URL + 'city';
        }
    }

    static ON_AIR(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'on-air/' + id;
        } else {
            return AppUrl.APP_URL + 'on-air';
        }
    }

    static ON_AIR_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'on-air-status/' + id;
        }
    }

    static INSIGHT_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'insight-status/' + id;
        }
    }
    static NEWS_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'news-status/' + id;
        }
    }

    static SCRIBBLE_WALL(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'scribble-wall/' + id;
        } else {
            return AppUrl.APP_URL + 'scribble-wall';
        }
    }

    static SCRIBBLE_WALL_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'scribble-wall-status/' + id;
        }
    }

    static SETTINGS(): string {
        return AppUrl.APP_URL + 'settings';
    }

    static UNIVERSITY_MARKETING_TAG(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'universities-marketing-tags/' + id;
        } else {
            return AppUrl.APP_URL + 'universities-marketing-tags';
        }
    }

    static UPDATE_UNIVERSITY_MARKETING_TAG(universityId, id): string {
        if (universityId && id) {
            return AppUrl.APP_URL + 'universities-marketing-tags/' + universityId + '/' + id;
        }
    }

    static ALL_MARKETING_TAG_COUNT(id?): string {
        if (id) {
            return AppUrl.API_URL + 'universities-marketing-tags-count/' + id;
        }
    }

    static POPULAR_COURSE_MARKETING_TAG(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'popular-course-marketing-tags/' + id;
        } else {
            return AppUrl.APP_URL + 'popular-course-marketing-tags';
        }
    }

    static UPDATE_POPULAR_COURSE_MARKETING_TAG(popularCourseId, id): string {
        if (popularCourseId && id) {
            return AppUrl.APP_URL + 'popular-course-marketing-tags/' + popularCourseId + '/' + id;
        }
    }
}
